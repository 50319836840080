<template>
    <div :id="chartID"></div>
</template>

<script setup lang="ts">
    import { ref, onMounted, watch } from "vue";
    import { getOrCreateProcedure } from 'o365-modules';

    const props = defineProps({
        chartConfig:{
            type: String,
            required: true,
            default: null
        },
        procedure:{
            type: String,
            required: true,
            default: null
        },
        procedureParams:{
            type: Object,
            required: false,
            default: null
        },
        dashboardUrl:{
            type: String,
            required: false,
            default: null
        }
    });

    const emit = defineEmits(["loaded"]);

    var loadDynamicChartProc = {};
    const chartID = ref("chart_"+crypto.randomUUID());
    let chart = null;
    
    function load(){
        if(!props.procedure) return
        loadDynamicChartProc = getOrCreateProcedure({ id: props.procedure, procedureName: props.procedure, useAlert: false });
        
        loadDynamicChartProc.execute(getProcedureParams()).then((response)=>{
            let data;
            if(response.hasOwnProperty("Table")){
                data = response.Table;
            }else{
                data = response;
            }

            const options = JSON.parse(props.chartConfig);
            const categoryColumn = options.categoryColumn.name;

            delete options.categoryColumn;
            delete options.seriesColumns;

            if (options.xAxis == null) { options.xAxis = {}};

            if (options.chart.type == 'pie') {
                for (let i = 0; i < data.length; i++) {
                    options.series[0].data.push({
                        name: data[i][categoryColumn],
                        y: data[i][options.series[0].name]
                    })
                }
                options.series[0].name = categoryColumn;
            }else{
                options.xAxis.categories = data.map(item => item[categoryColumn]);
                options.series.forEach(seriesColumn => {
                    seriesColumn.data = data.map(item => item[seriesColumn.name]); 

                    if (seriesColumn.animation == null) {
                        seriesColumn.animation = {
                            duration: 500,
                            easing: 'easeOutBounce'
                        };
                    }
                });
            }
            chart = Highcharts.chart(chartID.value, options);
            emit("loaded");
        }).catch((err)=>{
            console.log(err)
        });

    }

    function getProcedureParams(){
        if(props.procedureParams){
            let cleanedUpObj = {};
            Object.entries(props.procedureParams).forEach(([key, value])=>{
                if(value){
                    cleanedUpObj[key] = value
                }else{
                    cleanedUpObj[key] = null

                }
            });
            return cleanedUpObj
        }else if(props.dashboardUrl){
            let procParams = {};
            new URLSearchParams(props.dashboardUrl.split("?")[1]).forEach((value, key)=>{
                if(key == "ID") return; // to not pass the chart ID as param
                procParams[key] = value;
            });
            return procParams
        }else{
            return {}
        }
    }

    watch(()=>props.procedureParams, ()=>{
        if(chartID.value){
            load();
        }
    }, {immediate: true, deep: true})

    onMounted(()=>{
        load();
    });
</script>